import React from "react";
import { Translation } from "react-i18next";

export const BannerEmpresa = {
  minheight: true,
  showbutton: false,
  bannerTitulo: (
    <Translation>{(t, { i18n }) => <p>{t("Banner.5")}</p>}</Translation>
  ),
  bannerContext: (
    <Translation>{(t, { i18n }) => <p>{t("Banner.6")}</p>}</Translation>
  ),
  bannerButtonInf: "",
  BG: require("../../images/industria.jpg").default,
};

export const ServiceObjOne = {
  primary: false,
  lightBg: true,
  lightTopLine: false,
  lightText: false,
  lightTextDesc: false,

  topLine: (
    <Translation>{(t, { i18n }) => <p>{t("Service.1")}</p>}</Translation>
  ),

  headline: (
    <Translation>{(t, { i18n }) => <p>{t("Service.2")}</p>}</Translation>
  ),

  description: (
    <Translation>{(t, { i18n }) => <p>{t("Service.3")}</p>}</Translation>
  ),

  buttonLabel: "Get Started",

  imgStart: "",
  img: require("../../images/svg-7.svg").default,
  alt: "Credit Card",
  start: "",
  showButton: true,
  descripbtn: "...",
};

export const ServiceObjTwo = {
  primary: true,
  lightBg: false,
  lightTopLine: true,
  lightText: true,
  lightTextDesc: true,

  topLine: (
    <Translation>{(t, { i18n }) => <p>{t("Service.1")}</p>}</Translation>
  ),

  headline: (
    <Translation>{(t, { i18n }) => <p>{t("Service.4")}</p>}</Translation>
  ),

  description: (
    <Translation>{(t, { i18n }) => <p>{t("Service.5")}</p>}</Translation>
  ),

  buttonLabel: "Get Started",

  imgStart: true,
  img: require("../../images/svg-5.svg").default,
  alt: "Credit Card",
  start: "",
  showButton: true,
  descripbtn: "...",
};

export const ServiceObjtree = {
  primary: false,
  lightBg: true,
  lightTopLine: false,
  lightText: false,
  lightTextDesc: false,

  topLine: (
    <Translation>{(t, { i18n }) => <p>{t("Service.1")}</p>}</Translation>
  ),

  headline: (
    <Translation>{(t, { i18n }) => <p>{t("Service.6")}</p>}</Translation>
  ),

  description: (
    <Translation>{(t, { i18n }) => <p>{t("Service.7")}</p>}</Translation>
  ),

  buttonLabel: "Get Started",

  imgStart: "",
  img: require("../../images/svg-2.svg").default,
  alt: "Credit Card",
  start: "",
  showButton: true,
  descripbtn: "...",
};
