import React, { useState } from "react";
import { IconContext } from "react-icons/lib";
import { FaTimes, FaBars } from "react-icons/fa";

import { LogoSrc } from "../../images";

import {
  NavBarSection,
  ResponsiveIcon,
  NavbarContainer,
  NavLogo,
  Logo,
  NavMenu,
  NavBarItem,
  DropDownBarItem,
  NavBarLink,
  DropDownTitle,
  DropDownContent,
  DropDownLink,
} from "./NabBar.elements";

const NabBar = ({ t }) => {
  const [navcolor, setNavcolor] = useState(false);
  const [click, setClick] = useState(false);

  const ShowResponsiveBar = () => setClick(!click);
  const changerNavBarColor = () => {
    if (window.scrollY > 230) {
      setNavcolor(true);
    } else {
      setNavcolor(false);
    }
    console.log(window.scrollY);
  };
  window.addEventListener("scroll", changerNavBarColor);

  return (
    <>
      <IconContext.Provider value={{ color: "#000" }}>
        <NavBarSection navcolor={navcolor}>
          <NavbarContainer>
            <NavLogo to="/">
              <Logo src={LogoSrc} alt="logo" />
              Éxito Motriz Lda.
            </NavLogo>
            <ResponsiveIcon onClick={ShowResponsiveBar}>
              {click ? <FaTimes /> : <FaBars />}
            </ResponsiveIcon>

            <NavMenu onClick={ShowResponsiveBar} click={click}>
              <NavBarItem>
                <NavBarLink to="/">{t("Menu.1")}</NavBarLink>
              </NavBarItem>

              {/* <DropDownBarItem>
                <DropDownTitle>
                  <NavBarLink>{t("Menu.2")}</NavBarLink>
                </DropDownTitle>
                <DropDownContent>
                  <DropDownLink to="/weare">{t("Menu.2-1")}</DropDownLink>
                  <DropDownLink>{t("Menu.2-2")}</DropDownLink>
                  <DropDownLink>{t("Menu.2-2")}</DropDownLink>
                </DropDownContent>
              </DropDownBarItem> 

              <NavBarItem>
                <NavBarLink to={"/service"}>{t("Menu.3")}</NavBarLink>
              </NavBarItem>

              <NavBarItem>
                <NavBarLink to="/catalog">{t("Menu.4")}</NavBarLink>
              </NavBarItem>
              <NavBarItem>
                <NavBarLink>{t("Menu.5")}</NavBarLink>
              </NavBarItem>

              <NavBarItem>
                <NavBarLink>{t("Menu.6")}</NavBarLink>
              </NavBarItem>*/}
            </NavMenu>
          </NavbarContainer>
        </NavBarSection>
      </IconContext.Provider>
    </>
  );
};

export default NabBar;
