import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import WebFont from "webfontloader";

import "./i18next";
import App from "./App";

WebFont.load({
  google: {
    families: ["Roboto:400,900", "sans-serif"],
    //families: ["Rokkitt:400,700,900", "sans-serif"],
  },
});

ReactDOM.render(
  <Suspense fallback={<div>Loading ...</div>}>
    <React.StrictMode>
      <App />
    </React.StrictMode>
  </Suspense>,

  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
