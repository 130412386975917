import React from "react";

import { IconContext } from "react-icons/lib";
import {
  FaFacebook,
  FaInstagram,
  FaLinkedin,
  FaTwitter,
  FaYoutube,
} from "react-icons/fa";

/* Flag*/

import { Spain, English, Portugues } from "../../images";

import { FlagLanguage } from "../../globalStyles";

import {
  SectionLanguage,
  SectionLanguageWrap,
  SocialIcons,
  SocialIconLink,
  ListLanguages,
} from "./NavLanguagebar.elements";

const NavLanguagebar = ({ i18n }) => {
  function LanguageClick(lang) {
    i18n.changeLanguage(lang);
  }
  return (
    <IconContext.Provider value={{ color: "#f28121" }}>
      <SectionLanguage>
        <SectionLanguageWrap>
          <ListLanguages>
            <FlagLanguage
              src={Portugues}
              alt="portugues"
              onClick={() => LanguageClick("pt")}
            />

            <FlagLanguage
              src={Spain}
              alt="spain"
              onClick={() => LanguageClick("es")}
            />
            <FlagLanguage
              src={English}
              alt="english"
              onClick={() => LanguageClick("en")}
            />
          </ListLanguages>

          <SocialIcons>
            <SocialIconLink href="/" targer="_blank" aria-label="Facebook">
              <FaFacebook />
            </SocialIconLink>
            <SocialIconLink href="/" targer="_blank" aria-label="Instagram">
              <FaInstagram />
            </SocialIconLink>

            <SocialIconLink href="/" targer="_blank" aria-label="Twitter">
              <FaTwitter />
            </SocialIconLink>
          </SocialIcons>
        </SectionLanguageWrap>
      </SectionLanguage>
    </IconContext.Provider>
  );
};

export default NavLanguagebar;
