import React from "react";
import { Button } from "../../globalStyles";
import { AiFillThunderbolt } from "react-icons/ai";
import { GiCrystalBars } from "react-icons/gi";
import { GiCutDiamond, GiRock } from "react-icons/gi";
import { GiFloatingCrystal } from "react-icons/gi";
import { IconContext } from "react-icons/lib";
import {
  PricingSection,
  PricingWrapper,
  PricingHeading,
  PricingDescription,
  PricingDescriptionFooter,
  PricingContainer,
  PricingCard,
  PricingCardInfo,
  PricingCardIcon,
  PricingCardPlan,
  PricingCardCost,
  PricingCardLength,
  PricingCardFeatures,
  PricingCardFeature,
} from "./Pricing.elements";

import { FadeIn, FadeInLeft } from "../../components";

const Pricing = ({
  lightBg,
  lightText,
  headline,
  headline2,
  item1,
  item2,
  item3,
  subitem1,
  subitem2,
  subitem3,
  img,
  bgimg1,
  bgimg2,
  bgimg3,
  cardfeature1,
  cardfeature2,
  cardfeature3,
  cardfeature21,
  cardfeature22,
  cardfeature23,
  cardfeature31,
  cardfeature32,
  cardfeature33,
  btnmore,
  pensamento,
}) => {
  return (
    <IconContext.Provider value={{ color: "#fff", size: 64 }}>
      <PricingSection lightBg={lightBg}>
        <PricingWrapper>
          <PricingHeading lightText={lightText}>{headline}</PricingHeading>

          <PricingDescription>{headline2}</PricingDescription>

          <PricingContainer>
            <PricingCard to="/service" img={img} bgimg={bgimg1}>
              <PricingCardInfo>
                <PricingCardIcon>
                  <GiRock />
                </PricingCardIcon>
                <PricingCardPlan>{item1}</PricingCardPlan>
                <PricingCardCost>-</PricingCardCost>
                <PricingCardLength>{subitem1}</PricingCardLength>
                <PricingCardFeatures>
                  <PricingCardFeature>{cardfeature1}</PricingCardFeature>
                  <PricingCardFeature>{cardfeature2}</PricingCardFeature>
                  <PricingCardFeature>{cardfeature3}</PricingCardFeature>
                </PricingCardFeatures>
                <Button primary>{btnmore}</Button>
              </PricingCardInfo>
            </PricingCard>

            <PricingCard to="/service" img={img} bgimg={bgimg2}>
              <PricingCardInfo>
                <PricingCardIcon>
                  <GiCrystalBars />
                </PricingCardIcon>
                <PricingCardPlan>{item2}</PricingCardPlan>
                <PricingCardCost>-</PricingCardCost>
                <PricingCardLength>{subitem2}</PricingCardLength>
                <PricingCardFeatures>
                  <PricingCardFeature>{cardfeature21}</PricingCardFeature>
                  <PricingCardFeature>{cardfeature22}</PricingCardFeature>
                  <PricingCardFeature>{cardfeature23}</PricingCardFeature>
                </PricingCardFeatures>
                <Button primary>{btnmore}</Button>
              </PricingCardInfo>
            </PricingCard>

            <PricingCard to="/service" img={img} bgimg={bgimg3}>
              <PricingCardInfo>
                <PricingCardIcon>
                  <GiCutDiamond />
                </PricingCardIcon>
                <PricingCardPlan>{item3}</PricingCardPlan>
                <PricingCardCost>-</PricingCardCost>
                <PricingCardLength>{subitem3}</PricingCardLength>
                <PricingCardFeatures>
                  <PricingCardFeature>{cardfeature31}</PricingCardFeature>
                  <PricingCardFeature>{cardfeature32}</PricingCardFeature>
                  <PricingCardFeature>{cardfeature33}</PricingCardFeature>
                </PricingCardFeatures>
                <Button primary>{btnmore}</Button>
              </PricingCardInfo>
            </PricingCard>
          </PricingContainer>
          <PricingDescriptionFooter>{pensamento}</PricingDescriptionFooter>
          <h1></h1>
        </PricingWrapper>
      </PricingSection>
    </IconContext.Provider>
  );
};
export default Pricing;
