export const ImageSliderData = [
  {
    title: "Welcome to Space",
    subtitle: "Lorem ipsum dolor, sit amet consectetur adipisicing elit.",
    imagebanner: require("../../images/SliderImage/imagem-1.jpg").default,
  },
  {
    title: "Ola Benvindos 2",
    subtitle: "Lorem ipsum dolor, sit amet consectetur adipisicing elit.",
    imagebanner: require("../../images/SliderImage/imagem-2.jpg").default,
  },
  {
    title: "Ola Benvindos 3",
    subtitle: "Lorem ipsum dolor, sit amet consectetur adipisicing elit.",
    imagebanner: require("../../images/SliderImage/imagem-3.jpg").default,
  },
  {
    title: "Welcome to Space",
    subtitle: "Lorem ipsum dolor, sit amet consectetur adipisicing elit.",
    imagebanner: require("../../images/SliderImage/imagem-4.png").default,
  },
  {
    title: "Ola Benvindos 2",
    subtitle: "Lorem ipsum dolor, sit amet consectetur adipisicing elit.",
    imagebanner: require("../../images/SliderImage/imagem-5.jpg").default,
  },
  {
    title: "Ola Benvindos 3",
    subtitle: "Lorem ipsum dolor, sit amet consectetur adipisicing elit.",
    imagebanner: require("../../images/SliderImage/imagem-5.jpg").default,
  },
];
