import React from "react";

import {
  BannerSection,
  BannerContent,
  BannerContext,
  BannerBtn,
  BannerTitle,
} from "./Banner.elements";

import { Button } from "../../globalStyles";

const Banner = ({
  minheight,
  showbutton,
  bannerTitulo,
  bannerContext,
  bannerButtonInf,
  BG,
}) => {
  return (
    <BannerSection minheight={minheight} imgUrl={BG}>
      <BannerContent>
        <BannerTitle>{bannerTitulo}</BannerTitle>
        <BannerContext>{bannerContext}</BannerContext>
        {showbutton ? <Button primary>{bannerButtonInf}</Button> : <h1></h1>}
      </BannerContent>
    </BannerSection>
  );
};

export default Banner;
