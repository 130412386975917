import styled from "styled-components";

/**BANNER */

export const BannerSection = styled.section`
  position: relative;
  width: 100%;
  //min-height: ${({ minheight }) => (minheight ? "40vh" : "10vh")};
  min-height: ${(props) => props.minheight};
  display: flex;
  justify-content: center;
  align-items: center;

  background-image: ${({ imgUrl }) => `url(${imgUrl})`};
  background-size: cover;
`;

export const BannerContent = styled.div`
  max-width: 900px;
  text-align: center;
`;

export const BannerTitle = styled.h2`
  margin-top: 3rem;
  font-size: 3.5rem;
  color: #fff;

  @media screen and (max-width: 768px) {
    font-size: 2rem;
  }
`;

export const BannerContext = styled.p`
  font-size: 1.5rem;
  color: #fff;
`;

export const BannerBtn = styled.button`
  color: #fff;

  display: inline-block;
  padding: 10px 30px;
  margin-top: 20px;
  text-transform: uppercase;
  text-decoration: none;
  letter-spacing: 2px;
  transition: 0.5s;
  border-radius: 20px;
  border: none;
  cursor: pointer;

  &:hover {
    letter-spacing: 6px;
    border: none;
  }
`;
