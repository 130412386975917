import { Component } from "react";
import styled from "styled-components";

export const SectionLanguage = styled.div`
  background: #f2f4f8;
  border-bottom: 1px solid #ebe6e6;
`;

export const SectionLanguageWrap = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 90%;
  max-width: 1000px;
  margin: 0px auto 0 auto;

  @media screen and (max-width: 820px) {
    flex-direction: column;
  }
`;

export const ListLanguages = styled.section`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-bottom: 0px;
  padding: 5px;
  color: #fff;
`;

export const SocialIcons = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 140px;
`;

export const SocialIconLink = styled.a`
  font-size: 20px;
`;
