import React from "react";
import styled from "styled-components";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation, Pagination } from "swiper";

import "swiper/swiper-bundle.min.css";

import { ImageSliderData } from "./Data";

SwiperCore.use([Navigation, Pagination]);

const ContainerSws = styled.div`
  display: flex;
  flex-wrap: wrap;
  font-size: 1rem;
  text-align: center;
`;

const SwsTitulo = styled.div`
  margin: 60px;
  font-size: 2rem;
  color: #000;
  text-align: center;
  .swiper-container {
    width: 800px;
    padding-top: 15px;
    padding-bottom: 15px;
    //background: #ee5113;
    //padding: 10px;
  }
  .swiper-slide {
    background-position: center;
    background-size: cover;
    width: 100%;
    height: 460px;
    display: flex;
    justify-content: center;
  }

  img {
    max-width: 150px;
  }

  .swiper-slide img {
    position: absolute;
    bottom: 0;
    max-height: 450px;
  }
`;

const SliderSwiper = ({ widthleft, widthright }) => {
  return (
    <>
      <SwsTitulo>Nossos Produtos</SwsTitulo>
      <ContainerSws>
        <Swiper
          id="main"
          tag="section"
          wrapperTag="ul"
          // navigation
          pagination
          spaceBetween={0}
          slidesPerView={2}
          loop
        >
          {ImageSliderData.map((data, index) => {
            return (
              <>
                <SwiperSlide key={index} tag="li" style={{ listStyle: "none" }}>
                  <h1>{data.title}</h1>
                  <p>{data.subtitle}</p>
                  <img
                    src={data.imagebanner}
                    style={{ width: "250px" }}
                    alt={data.title}
                  />
                </SwiperSlide>
              </>
            );
          })}
        </Swiper>
      </ContainerSws>
    </>
  );
};

export default SliderSwiper;
