import styled, { keyframes } from "styled-components";
import BaseAnimation from "./BaseAnimation.elements";

const FadeInAnimation = keyframes`  
  from { opacity: 0; 
      transition-duration: 1s ease;
  }
  to { opacity: 1; 
    transition-duration: 1s ;
    transform: scale(1.08)
  }
`;

const FadeIn = styled(BaseAnimation)`
  animation-name: ${FadeInAnimation};
`;

export default FadeIn;
