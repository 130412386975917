import styled from "styled-components";

export const PortfolioContainer = styled.div`
  justify-items: center;
  row-gap: 2rem;
  max-width: 500px;
  display: grid;
  grid-template-columns: 100%;
  grid-column-gap: 2rem;
  width: calc(100% - 2rem);
  margin-left: 1rem;
  margin-right: 1rem;
  margin-bottom: 10px;
`;

export const PortfolioImg = styled.div`
  position: relative;
  overflow: hidden;
  border-radius: 0.5rem;
`;

export const Img = styled.img`
  padding-right: 0;
  border: 0;
  max-width: 100%;
  vertical-align: middle;
  display: inline-block;
  max-height: 500px;
`;

export const PortfolioLink = styled.div`
  position: absolute;
  bottom: -90%;
  width: 100%;
  height: 100%;
  display: flex;
  //flex-direction: column;
  justify-content: center;
  padding-top: 5px;
  color: #fff;
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 0.5rem;
  cursor: pointer;
  backdrop-filter: blur(10px);
  transition: 0.3s;

  &:hover {
    bottom: 0;
    justify-content: center;
    align-items: center;
    //flex-direction: column;
  }
`;

export const TituleFeatures = styled.p`
  font-size: 14px;
  margin-bottom: 24px;
`;

export const ListFeatures = styled.ul`
  margin: 16px 0 32px;
  list-style: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #a9b3c1;
`;

export const ListFeaturesItems = styled.li`
  margin-bottom: 10px;
`;
