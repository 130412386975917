import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import GlobalStyle, { BackgroudSmall } from "./globalStyles";

/* Components */
import { Footer, NavLanguagebar, NabBar } from "./components";
import ScrollToTop from "./components/ScrollToTop";

/**Pages */

import Home from "./pages/HomePage/Home";
import EmpresaPage from "./pages/EmpresaPage/EmpresaPage";
import Servicepage from "./pages/ServicePage/Servicepage";
import CatalogoPage from "./pages/CatalogoPage/CatalogoPage";

const App = () => {
  const { t, i18n } = useTranslation();
  return (
    <Router>
      <GlobalStyle />
      <ScrollToTop />
      <BackgroudSmall>
        <NavLanguagebar i18n={i18n} />
        <NabBar t={t} />

        <Switch>
          <Route path="/" exact component={Home} />
          {/*  <Route path="/weare" exact component={EmpresaPage} />
          <Route path="/service" component={Servicepage} />
          <Route path="/catalog" component={CatalogoPage} /> */}
        </Switch>
        <Footer />
      </BackgroudSmall>
    </Router>
  );
};

export default App;
