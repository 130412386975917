import styled from "styled-components";

import BG from "../../images/separador-cat.png";

export const LineaSection = styled.section`
  position: relative;
  width: 100%;
  min-height: 0.5vh;
  display: flex;
  padding-left: 50px;
  padding-right: 50px;
  padding-bottom: 1px;
`;

export const LineaContentImg = styled.div`
  justify-content: center;
  align-items: center;

  width: 100%;
  background: url(${BG});
  background-size: cover;
`;

export const LineSeparationColor = styled.div`
  background-color: #f28121;
  width: 100%;
`;
