import React, { useState } from "react";
import styled from "styled-components";
import { FaTimes, FaBars, FaWindows } from "react-icons/fa";
import { Swiper, SwiperSlide } from "swiper/react";

import SwiperCore, {
  Navigation,
  Pagination,
  EffectCube,
  EffectFade,
  EffectCoverflow,
  EffectFlip,
  Autoplay,
} from "swiper";

import "swiper/swiper-bundle.min.css";

SwiperCore.use([
  Navigation,
  Pagination,
  EffectCube,
  EffectFade,
  EffectCoverflow,
  EffectFlip,
  Autoplay,
]);

const Section = styled.div`
  position: relative;
  width: 100%;
  min-height: 60vh;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  //background: #000;
  //color: #835e0d;

  @media screen and (max-width: 800px) {
    padding: 15px 10px;
  }
`;

const Circle = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #e51e2a;
  //clip-path: circle(10% at 0);
  //clip-path: circle(10% at 100%);
  clip-path: ${({ circleposition }) =>
    circleposition ? "circle(10% at 0)" : "circle(10% at 100%)"};
`;

const ContentHeader = styled.div`
  //background: #f8061a;
  padding: 10px;
  position: relative;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  //flex-direction: row;
`;

const SwiperBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  //background: #bd05eb;
  //padding: 10px;

  .swiper-container {
    width: 800px;
    padding-top: 15px;
    padding-bottom: 15px;
    //background: #ee5113;
    //padding: 10px;
  }
  .swiper-slide {
    background-position: center;
    background-size: cover;
    width: 100%;
    height: 460px;
    display: flex;
    justify-content: center;
  }

  img {
    max-width: 150px;
  }

  .swiper-slide img {
    position: absolute;
    bottom: 0;
    max-height: 450px;
  }

  @media screen and (max-width: 800px) {
    .swiper-slide img {
      position: relative;
      bottom: 0;
      max-height: 250px;
    }

    img {
      max-width: 50px;
    }
  }
`;

const Content = styled.div`
  width: 100%;

  //position: relative;
  display: flex;
  //flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  //background-color: #70e70e;
  //padding-left: 10px;

  @media screen and (max-width: 800px) {
    flex-direction: column;
  }
`;

const Leftcolum = styled.div`
  height: 450px;
  width: 100%;
  flex: 50%;
  padding: 100px 50px 10px 30px;
  //background-color: #cc2867;

  h2 {
    color: #000;
    font-size: 2.5em;
    margin-bottom: 10px;
    line-height: 1.4em;
    font-weight: 700;
  }
  p {
    color: #000;
    font-size: 1em;
  }
  a {
    display: inline-block;
    margin-top: 20px;
    padding: 8px 20px;
    background: #f28121;
    color: #fff;
    border-radius: 40px;
    font-weight: 500;
    letter-spacing: 1px;
    text-decoration: none;
  }

  @media screen and (max-width: 800px) {
    flex: 100%;
    height: 250px;
    padding: 20px 30px 0 30px;
    text-align: center;
    h2 {
      text-align: center;
    }
    p {
      color: #000;
      font-size: 1em;
    }
    a {
    }
  }
`;

const RightColum = styled.div`
  //background-color: #c9189d;
  height: 450px;
  width: 100%;
  padding: 10px;
  flex: 50%;
  display: flex;
  justify-content: center;

  img {
    max-width: 270px;
  }

  @media screen and (max-width: 800px) {
    flex: 100%;
    height: 150px;
  }
`;

export const PricingSection = styled.div`
  padding: 50px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: "#fff";
  align-items: center;
`;

export const PricingHeading = styled.h1`
  font-size: 48px;
  line-height: 1.1;
  font-weight: 600;
  color: "#000";
`;

const ProductInfoSlide = ({ headline, ProductListData }) => {
  return (
    <PricingSection>
      <PricingHeading>{headline}</PricingHeading>

      <Section>
        <ContentHeader>
          <SwiperBox>
            <Swiper
              id="main"
              tag="section"
              wrapperTag="ul"
              spaceBetween={0}
              slidesPerView={1}
              loop
            >
              {ProductListData.map((data, index) => {
                return (
                  <>
                    <SwiperSlide
                      key={index}
                      tag="li"
                      style={{ listStyle: "none" }}
                    >
                      <Content>
                        <Leftcolum>
                          <h2>{data.title}</h2>
                          <p>{data.subtitle}</p>
                          <a href="#">Lear More</a>
                        </Leftcolum>

                        <RightColum>
                          <img
                            src={data.imagebanner}
                            alt={data.titulo}
                            //onChange={() => setProduct(data.titulo)}
                          />
                        </RightColum>
                      </Content>
                    </SwiperSlide>
                  </>
                );
              })}
            </Swiper>
          </SwiperBox>
        </ContentHeader>
      </Section>
    </PricingSection>
  );
};

export default ProductInfoSlide;
