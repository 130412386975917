import React from "react";

import { LineaSection, LineSeparationColor } from "./Lineaseparation.elements";

const LineSeparation = () => {
  return (
    <LineaSection>
      <LineSeparationColor />
    </LineaSection>
  );
};

export default LineSeparation;
