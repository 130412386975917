import styled, { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
//@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;900&display=swap');

*, body, html {
  
    box-sizing: border-box;
    margin: 0;
    padding:0;
    font-family:  "Roboto", sans-serif;
    //font-family: "Rokkitt", Arial, Helvetica, sans-serif;
    
}
`;

export const BackgroudSmall = styled.div`
  background-color: #fff;

  @media screen and (min-width: 1300px) {
    background-color: #f2f4f8;
    padding-right: 20px;
    padding-left: 20px;
  }
`;

export const Container = styled.div`
  z-index: 1;
  width: 100%;
  max-width: 1300px;
  margin-right: auto;
  margin-left: auto;
  padding-right: 50px;
  padding-left: 50px;

  @media screen and (max-width: 991px) {
    padding-right: 30px;
    padding-left: 30px;
  }
`;

export const Button = styled.button`
  border-radius: 4px;
  background: ${({ primary }) => (primary ? "#f28121" : "#006caa")};
  white-space: nowrap;
  padding: ${({ big }) => (big ? "12px 64px" : "10px 20px")};
  color: #fff;
  font-size: ${({ fontBig }) => (fontBig ? "20px" : "16px")};
  outline: none;
  border: none;
  cursor: pointer;
  margin: 2px;

  &:hover {
    transition: all 0.3s ease-out;
    background: ${({ primary }) => (primary ? "#006caa" : "#f28121")};
  }

  @media screen and (max-width: 960px) {
    width: 100%;
  }
`;

export const Logo = styled.img`
  width: 50px;
  height: 50px;
  margin-right: 5px;
`;

export const FlagLanguage = styled.img`
  width: 20px;
  height: 20px;
  margin-right: 1px;
  cursor: pointer;
  border-radius: 4px;

  @media screen and (max-width: 960px) {
    border-radius: 0px;
    margin-top: 10px;
    flex-direction: row;
    justify-self: start;
  }
`;

export const ListFilter = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-bottom: 0px;
  padding: 5px;
  color: #fff;
  background: #fff;
  @media screen and (max-width: 960px) {
    margin: 0 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;
export const CatalogSection = styled.div`
  padding: 50px 0;
  display: flex;
  justify-content: center;
  //background-image: linear-gradient(to left, #f7f8fa, #fff);
  background: #fff;
`;

export const CatalogWrapper = styled.div`
  background: #fff;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  @media screen and (max-width: 960px) {
    margin: 0 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

export const Linea = styled.div`
  display: block;
  unicode-bidi: isolate;
  margin-block-start: 0.5em;
  margin-block-end: 0.5em;
  margin-inline-start: auto;
  margin-inline-end: auto;
  overflow: hidden;
  border-style: inset;
  border-width: 1px;
  width: ${(props) => props.widtline};
  border-color: ${(props) => props.colorline};
`;

export const Separatorimg = styled.div`
  background-image: url(${(props) => props.img});
  width: 100%;
  height: 30px;
  background-size: cover;
  background-position: center;
`;

export default GlobalStyle;
