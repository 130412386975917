import { Link } from "react-router-dom";
import styled from "styled-components";

import bgdefault from "../../images/svg-9.svg";

export const PricingSection = styled.div`
  padding: 100px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: ${({ lightBg }) => (lightBg ? "#fff" : "#006caa")};
`;

export const PricingWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  @media screen and (max-width: 960px) {
    margin: 0 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

export const PricingHeading = styled.h1`
  margin-bottom: 24px;
  font-size: 48px;
  line-height: 1.1;
  font-weight: 600;
  color: ${({ lightText }) => (lightText ? "#f7f8fa" : "#1c2237")};
`;

export const PricingDescription = styled.p`
  max-width: 840px;
  margin-bottom: 35px;
  font-size: 18px;
  line-height: 24px;
  color: ${({ lightText }) => (lightText ? "#a9b3c1" : "#1c2237")};
`;

export const PricingDescriptionFooter = styled.h3`
  margin-top: 20px;
  font-size: 18px;
  margin-bottom: 1px;
  color: ${({ lightText }) => (lightText ? "#fff" : "#000")};
`;

export const PricingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  //background: #006caa;

  @media screen and (max-width: 960px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
`;

export const PricingCard = styled(Link)`
  background: ${({ bgimg }) => (bgimg ? `url(${bgimg})` : "#006caa")};
  background-size: cover;

  //background-image: linear-gradient(to top, #006caa, #f28121);

  box-shadow: 0 6px 20px rgba(56, 125, 255, 0.2);
  width: 280px;
  height: 500px;
  margin-right: 10px;
  text-decoration: none;
  border-radius: 4px;
  bac &:nth-child(2) {
    margin: 24px;
  }
  &:hover {
    transform: scale(1.06);
    transition: all 0.3s ease-out;
    color: #1c2237;
    background: #006caa;
  }
  @media screen and (max-width: 960px) {
    width: 90%;
    margin-bottom: 10px;
    &:hover {
      transform: none;
    }
  }
`;

export const PricingCardInfo = styled.div`
  display: flex;
  flex-direction: column;
  height: 500px;
  padding: 24px;
  align-items: center;
  color: #fff;
`;

export const PricingCardIcon = styled.div`
  margin: 24px 0;
`;

export const PricingCardPlan = styled.h3`
  margin-bottom: 5px;
  font-size: 24px;
`;

export const PricingCardCost = styled.h4`
  font-size: 40px;
`;

export const PricingCardLength = styled.p`
  font-size: 14px;
  margin-bottom: 24px;
`;

export const PricingCardFeatures = styled.ul`
  margin: 16px 0 32px;
  list-style: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #a9b3c1;
`;

export const PricingCardFeature = styled.li`
  margin-bottom: 10px;
`;
