import React from "react";
import { Translation } from "react-i18next";

export const ImageSliderData = [
  { image: require("../../images/mineros.jpg").default },
  { image: require("../../images/Banner/minero3.jpg").default },
  { image: require("../../images/industria.jpg").default },
];

export const BannerEmpresa = {
  minheight: "40vh",
  showbutton: false,
  bannerTitulo: (
    <Translation>{(t, { i18n }) => <p>{t("Banner.1")}</p>}</Translation>
  ),
  bannerContext: (
    <Translation>{(t, { i18n }) => <p>{t("Banner.2")}</p>}</Translation>
  ),
  bannerButtonInf: "",
  BG: require("../../images/mineros.jpg").default,
};

export const homeObjOne = {
  primary: true,
  lightBg: false,
  lightTopLine: true,
  lightText: true,
  lightTextDesc: true,
  topLine: <Translation>{(t, { i18n }) => <p>{t("Home.1")}</p>}</Translation>,
  headline: <Translation>{(t, { i18n }) => <p>{t("Home.2")}</p>}</Translation>,
  description: (
    <Translation>{(t, { i18n }) => <p>{t("Home.3")}</p>}</Translation>
  ),
  buttonLabel: (
    <Translation>{(t, { i18n }) => <p>{t("Home.4")}</p>}</Translation>
  ),
  linkTo: "/weare",
  imgStart: true,
  img: require("../../images/svg-9.svg").default,
  alt: "home",
  start: "",
  showButton: true,
  descripbtn: "...",
};

export const PricingObjOne = {
  lightBg: true,
  lightText: false,
  headline: <Translation>{(t, { i18n }) => <p>{t("Home2.2")}</p>}</Translation>,
  headline2: (
    <Translation>{(t, { i18n }) => <p>{t("Home2.2-2")}</p>}</Translation>
  ),

  item1: <Translation>{(t, { i18n }) => <p>{t("Home2.3-1")}</p>}</Translation>,
  item2: <Translation>{(t, { i18n }) => <p>{t("Home2.3-2")}</p>}</Translation>,
  item3: <Translation>{(t, { i18n }) => <p>{t("Home2.3-3")}</p>}</Translation>,

  subitem1: (
    <Translation>{(t, { i18n }) => <p>{t("Home2.4-1")}</p>}</Translation>
  ),
  subitem2: (
    <Translation>{(t, { i18n }) => <p>{t("Home2.4-2")}</p>}</Translation>
  ),
  subitem3: (
    <Translation>{(t, { i18n }) => <p>{t("Home2.4-3")}</p>}</Translation>
  ),

  cardfeature1: (
    <Translation>{(t, { i18n }) => <p>{t("Home2.5-1")}</p>}</Translation>
  ),
  cardfeature2: (
    <Translation>{(t, { i18n }) => <p>{t("Home2.5-2")}</p>}</Translation>
  ),
  cardfeature3: (
    <Translation>{(t, { i18n }) => <p>{t("Home2.5-3")}</p>}</Translation>
  ),

  cardfeature21: (
    <Translation>{(t, { i18n }) => <p>{t("Home2.6-1")}</p>}</Translation>
  ),
  cardfeature22: (
    <Translation>{(t, { i18n }) => <p>{t("Home2.6-2")}</p>}</Translation>
  ),
  cardfeature23: (
    <Translation>{(t, { i18n }) => <p>{t("Home2.6-3")}</p>}</Translation>
  ),

  cardfeature31: (
    <Translation>{(t, { i18n }) => <p>{t("Home2.7-1")}</p>}</Translation>
  ),
  cardfeature32: (
    <Translation>{(t, { i18n }) => <p>{t("Home2.7-2")}</p>}</Translation>
  ),
  cardfeature33: (
    <Translation>{(t, { i18n }) => <p>{t("Home2.7-3")}</p>}</Translation>
  ),

  btnmore: <Translation>{(t, { i18n }) => <p>{t("Home2.8")}</p>}</Translation>,

  pensamento: (
    <Translation>{(t, { i18n }) => <p>{t("Home2.9")}</p>}</Translation>
  ),

  img: true,
  bgimg1: false,
  bgimg2: false,
  bgimg3: false,
};

export const ProductSection = {
  headline: (
    <Translation>{(t, { i18n }) => <p>{t("Home2.10")}</p>}</Translation>
  ),
};

export const ProductListData = [
  {
    title: <Translation>{(t, { i18n }) => <p>{t("Home2.11")}</p>}</Translation>,
    subtitle: (
      <Translation>{(t, { i18n }) => <p>{t("Home2.12")}</p>}</Translation>
    ),
    imagebanner: require("../../images/SliderImage/petroleo.png").default,
  },
  /*  {
    title: "Ola Benvindos 2",
    subtitle: "Lorem ipsum dolor, sit amet consectetur adipisicing elit.",
    imagebanner: require("../../images/SliderImage/imagem-2.jpg").default,
  },
  {
    title: "Ola Benvindos 3",
    subtitle: "Lorem ipsum dolor, sit amet consectetur adipisicing elit.",
    imagebanner: require("../../images/SliderImage/imagem-3.jpg").default,
  },
  {
    title: "Welcome to Space",
    subtitle: "Lorem ipsum dolor, sit amet consectetur adipisicing elit.",
    imagebanner: require("../../images/SliderImage/imagem-4.png").default,
  },
  {
    title: "Ola Benvindos 2",
    subtitle: "Lorem ipsum dolor, sit amet consectetur adipisicing elit.",
    imagebanner: require("../../images/SliderImage/imagem-5.jpg").default,
  },
  {
    title: "Ola Benvindos 3",
    subtitle: "Lorem ipsum dolor, sit amet consectetur adipisicing elit.",
    imagebanner: require("../../images/SliderImage/imagem-5.jpg").default,
  }, */
];

/** *********************************/

export const homeObjTwo = {
  primary: false,
  lightBg: true,
  lightTopLine: false,
  lightText: false,
  lightTextDesc: false,

  topLine: <Translation>{(t, { i18n }) => <p>{t("Home2.1")}</p>}</Translation>,
  headline: <Translation>{(t, { i18n }) => <p>{t("Home2.2")}</p>}</Translation>,
  description: (
    <Translation>{(t, { i18n }) => <p>{t("Home2.3")}</p>}</Translation>
  ),
  buttonLabel: (
    <Translation>{(t, { i18n }) => <p>{t("Home2.4")}</p>}</Translation>
  ),

  imgStart: "start",
  img: require("../../images/svg-7.svg").default,
  alt: "Credit Card",
  start: "true",
  showButton: false,
  descripbtn: "...",
};

export const homeObjThree = {
  primary: true,
  lightBg: false,
  lightTopLine: true,
  lightText: true,
  lightTextDesc: true,
  topLine: "Marketing Agency",
  headline: "Lead Generation Specialist for Online Businesses",
  description:
    "We help business owners increase their revenue. Our team of unique specialist can help you achieve your business goals.",
  buttonLabel: "Get Started",
  imgStart: "",
  img: require("../../images/svg-8.svg").default,
  alt: "Credit Card",
  start: "",
  showButton: true,
  descripbtn: "...",
};

export const homeObjFour = {
  primary: true,
  lightBg: true,
  lightTopLine: false,
  lightText: false,
  lightTextDesc: false,
  topLine: "Marketing Agency",
  headline: "Lead Generation Specialist for Online Businesses",
  description:
    "We help business owners increase their revenue. Our team of unique specialist can help you achieve your business goals.",
  buttonLabel: "Get Started",
  imgStart: true,
  img: require("../../images/svg-9.svg").default,
  alt: "Credit Card",
  start: "",
  showButton: true,
  descripbtn: "...",
};
