import styled from "styled-components";
import { Link } from "react-router-dom";

export const NavBarSection = styled.div`
  background-color: #fff;
  backdrop-filter: blur(10px);
  border-bottom: ${({ navcolor }) => (navcolor ? "3px solid #21b0f2" : "#fff")};
  background-color: ${({ navcolor }) =>
    navcolor ? "rgba(128, 112, 112, 0.1)" : "#fff"};
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  position: sticky;
  top: 0;
  z-index: 999;

  /*

  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding: 20px 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;*/
`;

export const Circle = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  background: #117de9;
  clip-path: ellipse(10% at right 10%);

  @media screen and (max-width: 600px) {
    clip-path: circle(75% at right 50%);
  }
`;

export const NavLogo = styled(Link)`
  color: #000;
  justify-self: flex-start;
  cursor: pointer;
  text-decoration: none;
  font-size: 1.2rem;
  display: flex;
  align-items: center;
`;

export const Logo = styled.img`
  width: 50px;
  height: 50px;
  margin-right: 5px;
`;

export const LogoTitle = styled.div`
  color: #000;
  font-style: normal;
  font-size: 1.5rem;
  font-weight: 500;
`;

export const ResponsiveIcon = styled.div`
  display: none;

  @media screen and (max-width: 600px) {
    display: block;
    transform: translate(-10%, 10%);
    font-size: 1.5rem;
    cursor: pointer;
  }
`;

export const NavbarContainer = styled.div`
  display: flex;
  //background: #d11f1f;
  justify-content: space-between;
  align-items: center;
  height: 80px;
  z-index: 1;
  width: 100%;
  max-width: 1300px;
  margin-right: auto;
  margin-left: auto;
  padding-right: 50px;
  padding-left: 50px;

  @media screen and (max-width: 600px) {
    padding-right: 30px;
    padding-left: 30px;
  }
`;

export const NavMenu = styled.ul`
  display: flex;
  align-items: center;
  list-style: none;
  text-align: center;

  @media screen and (max-width: 600px) {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 90vh;
    position: absolute;
    top: 70px;
    /** variavel control click aparece o menu de lado esquerdo
            <NavMenu onClick={handleClick} click={click}>
    */
    left: ${({ click }) => (click ? 0 : "-110%")};
    opacity: 1;
    transition: all 0.5s ease;
    background: #117de9;
  }
`;

export const NavBarItem = styled.li`
  height: 50px;
  border-bottom: 2px solid transparent;

  &:hover {
    border-bottom: 2px solid #006caa;
  }

  @media screen and (max-width: 600px) {
    width: 100%;
    height: 80px;
    &:hover {
      border: none;
    }
  }
`;

export const NavBarLink = styled(Link)`
  color: #000;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0.5rem;
  height: 100%;

  @media screen and (max-width: 600px) {
    text-align: center;
    padding: 2rem;
    width: 100%;
    display: table;
    color: #fff;

    &:hover {
      color: #22c5f7;
      transition: all 0.3s ease;
      //background-color: #ddd;
      // width: 100%;
    }
  }
`;

/**DROPDOWN */

export const DropDownContent = styled.div`
  display: none;
  position: absolute;
  background-color: #000;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;

  @media screen and (max-width: 600px) {
    position: relative;
    width: 100%;

    background-color: #117de9;
  }
`;

export const DropDownBarItem = styled.li`
  color: #fff;
  cursor: pointer;
  float: left;
  overflow: hidden;

  :hover ${DropDownContent} {
    display: block;
  }
`;

export const DropDownTitle = styled.li`
  height: 50px;
  border-bottom: 2px solid transparent;
  &:hover {
    border: none;
  }

  @media screen and (max-width: 600px) {
    height: 80px;
  }
`;

export const DropDownLink = styled(Link)`
  float: none;
  color: #fff;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  text-align: left;

  :hover {
    background-color: #ddd;
    color: black;
  }

  @media screen and (max-width: 600px) {
    color: #fff;
    text-align: center;
  }
`;
