import React from "react";
import { FaFacebook, FaInstagram, FaTwitter } from "react-icons/fa";

import { Linea, Logo } from "../../globalStyles";
import { LogoSrc } from "../../images";

import {
  FooterContainer,
  SocialMedia,
  SocialMediaWrap,
  SocialLogo,
  SocialIcons,
  SocialIconLink,
  WebsiteRights,
  CopyRight,
  CopyRightContainer,
  PullLeft,
  PullRight,
} from "./Footer.elements";

const Footer = () => {
  return (
    <>
      <FooterContainer>
        <Linea widtline="30%" colorline="#f28121" />

        <SocialMedia>
          <SocialMediaWrap>
            <SocialLogo to="/">
              {/**<SocialIcon />*/}
              <Logo src={LogoSrc} alt="" />
              Exito Motriz Lda.
            </SocialLogo>
            {/*  <WebsiteRights>Exito Motriz Lda. | 2020</WebsiteRights> */}
            <WebsiteRights>
              Alameda 5 de Outubro, Edifício América R/C, Sala F | 3850 005
              Albergaria-a-Velha
            </WebsiteRights>
            {/* <SocialIcons>
              <SocialIconLink href="/" targer="_blank" aria-label="Facebook">
                <FaFacebook />
              </SocialIconLink>
              <SocialIconLink href="/" targer="_blank" aria-label="Instagram">
                <FaInstagram />
              </SocialIconLink>
              <SocialIconLink href="/" targer="_blank" aria-label="Twitter">
                <FaTwitter />
              </SocialIconLink>
            </SocialIcons> */}
          </SocialMediaWrap>
        </SocialMedia>
      </FooterContainer>
      <CopyRight>
        <CopyRightContainer>
          <PullLeft>
            Éxito Motriz, Lda - Todos os Direitos Reservados 2021
          </PullLeft>
          <PullRight>TuaWebmobile.com</PullRight>
        </CopyRightContainer>
      </CopyRight>
    </>
  );
};

export default Footer;
