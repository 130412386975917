import React from "react";
import { Translation } from "react-i18next";

export const BannerEmpresa = {
  minheight: "40vh",
  showbutton: false,
  bannerTitulo: (
    <Translation>{(t, { i18n }) => <p>{t("Banner.1")}</p>}</Translation>
  ),
  bannerContext: (
    <Translation>{(t, { i18n }) => <p>{t("Banner.2")}</p>}</Translation>
  ),
  bannerButtonInf: "",
  BG: require("../../images/Banner/minero3.jpg").default,
};

export const EmpresaObjOne = {
  primary: true,
  lightBg: false,
  lightTopLine: true,
  lightText: true,
  lightTextDesc: true,
  topLine: <Translation>{(t, { i18n }) => <p>{t("Banner.1")}</p>}</Translation>,
  headline: (
    <Translation>{(t, { i18n }) => <p>{t("Empresa.1")}</p>}</Translation>
  ),
  description: (
    <Translation>{(t, { i18n }) => <p>{t("Empresa.2")}</p>}</Translation>
  ),
  buttonLabel: (
    <Translation>{(t, { i18n }) => <p>{t("Banner.4")}</p>}</Translation>
  ),
  linkTo: "/weare",
  imgStart: true,
  img: require("../../images/svg-9.svg").default,
  alt: "home",
  start: "",
  showButton: false,
  descripbtn: "Éxito Motriz, Lda.",
};

export const EmpresaObjTwo = {
  primary: true,
  lightBg: true,
  lightTopLine: false,
  lightText: false,
  lightTextDesc: false,
  topLine: <Translation>{(t, { i18n }) => <p>{t("Banner.1")}</p>}</Translation>,
  headline: (
    <Translation>{(t, { i18n }) => <p>{t("Empresa.3")}</p>}</Translation>
  ),
  description: (
    <Translation>{(t, { i18n }) => <p>{t("Empresa.4")}</p>}</Translation>
  ),
  buttonLabel: (
    <Translation>{(t, { i18n }) => <p>{t("Banner.4")}</p>}</Translation>
  ),
  linkTo: "/weare",
  imgStart: false,
  img: require("../../images/svg-9.svg").default,
  alt: "home",
  start: "",
  showButton: false,
  descripbtn: "Éxito Motriz, Lda.",
};
