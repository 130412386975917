import React from "react";
import { Translation } from "react-i18next";

export const BannerEmpresa = {
  minheight: true,
  showbutton: false,
  bannerTitulo: (
    <Translation>{(t, { i18n }) => <p>{t("Banner.3")}</p>}</Translation>
  ),
  bannerContext: (
    <Translation>{(t, { i18n }) => <p>{t("Banner.4")}</p>}</Translation>
  ),
  bannerButtonInf: "",
  BG: require("../../images/bg.jpg").default,
};

export const listacategorias = [
  {
    id: 1,
    desc: (
      <Translation>{(t, { i18n }) => <p>{t("Catalogos.10")}</p>}</Translation>
    ),
  },
  {
    id: 2,
    desc: (
      <Translation>{(t, { i18n }) => <p>{t("Catalogos.2")}</p>}</Translation>
    ),
  },
  {
    id: 3,
    desc: (
      <Translation>{(t, { i18n }) => <p>{t("Catalogos.4")}</p>}</Translation>
    ),
  },
  {
    id: 4,
    desc: (
      <Translation>{(t, { i18n }) => <p>{t("Catalogos.6")}</p>}</Translation>
    ),
  },
  {
    id: 5,
    desc: (
      <Translation>{(t, { i18n }) => <p>{t("Catalogos.8")}</p>}</Translation>
    ),
  },
];

export const ListCatalogos = [
  {
    idcat: 2,
    categorie: (
      <Translation>{(t, { i18n }) => <p>{t("Catalogos.2")}</p>}</Translation>
    ),
    catalogTitle: (
      <Translation>{(t, { i18n }) => <p>{t("Catalogos.1")}</p>}</Translation>
    ),
    catalogSubCategorie: (
      <Translation>{(t, { i18n }) => <p>{t("Catalogos.2")}</p>}</Translation>
    ),
    start: true,
    img: require("../../images/bg.jpg").default,
    alt: "catalogo1",
  },
  {
    idcat: 3,
    categorie: (
      <Translation>{(t, { i18n }) => <p>{t("Catalogos.4")}</p>}</Translation>
    ),
    catalogTitle: (
      <Translation>{(t, { i18n }) => <p>{t("Catalogos.3")}</p>}</Translation>
    ),
    catalogSubCategorie: (
      <Translation>{(t, { i18n }) => <p>{t("Catalogos.4")}</p>}</Translation>
    ),
    start: false,
    img: require("../../images/bg.jpg").default,
    alt: "catalogo2",
  },
  {
    idcat: 3,
    categorie: (
      <Translation>{(t, { i18n }) => <p>{t("Catalogos.4")}</p>}</Translation>
    ),
    catalogTitle: (
      <Translation>{(t, { i18n }) => <p>{t("Catalogos.3")}</p>}</Translation>
    ),
    catalogSubCategorie: (
      <Translation>{(t, { i18n }) => <p>{t("Catalogos.4")}</p>}</Translation>
    ),
    start: false,
    img: require("../../images/bg.jpg").default,
    alt: "catalogo2",
  },
  {
    idcat: 4,
    categorie: (
      <Translation>{(t, { i18n }) => <p>{t("Catalogos.6")}</p>}</Translation>
    ),
    catalogTitle: (
      <Translation>{(t, { i18n }) => <p>{t("Catalogos.5")}</p>}</Translation>
    ),
    catalogSubCategorie: (
      <Translation>{(t, { i18n }) => <p>{t("Catalogos.6")}</p>}</Translation>
    ),
    start: false,
    img: require("../../images/bg.jpg").default,
    alt: "catalogo2",
  },
];
