import styled, { keyframes } from "styled-components";
import BaseAnimation from "./BaseAnimation.elements";

const FadeInLeftAnimation = keyframes`

    0% {
        opacity: 0;
        transform: translate3d(100%, 0px, 0px);
    }
    100% {
        opacity: 1;
        transform: none;
    }
`;

const FadeInLeft = styled(BaseAnimation)`
  animation-name: ${FadeInLeftAnimation};
`;

export default FadeInLeft;
