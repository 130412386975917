import styled from "styled-components";

export const SliderSection = styled.div`
  position: relative;
  height: 40vh;
  width: 100%;
  display: flex;
`;

export const Img = styled.img`
  width: 1960px;
  height: 100%;
  border-radius: 10px;
`;

export const RightArrow = styled.div`
  position: absolute;
  top: 50%;
  right: 32px;
  font-size: 32px;
  color: #000;
  z-index: 10;
  cursor: pointer;
  user-select: none;
`;

export const LeftArrow = styled.div`
  position: absolute;
  top: 50%;
  left: 32px;
  font-size: 32px;
  color: #000;
  z-index: 10;
  cursor: pointer;
  user-select: none;
`;

export const SliderDisplay = styled.div`
  opacity: 0;
  transition-duration: 1s ease;
`;

export const SliderDisplayActive = styled.div`
  opacity: 1;
  transition-duration: 1s;
  transform: scale(1.08);
`;
