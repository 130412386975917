import React, { useState } from "react";

import {
  BannerEmpresa,
  homeObjOne,
  PricingObjOne,
  ProductSection,
  ProductListData,
} from "./Data";

import {
  Banner,
  LineSeparation,
  FadeInLeft,
  InfoSection,
  Pricing,
  ProductInfoSlide,
} from "../../components";

const Home = () => {
  const [animar, setAnimar] = useState(false);

  const animationSection = () => {
    if (window.scrollY > 100) {
      setAnimar(true);
    } else {
      setAnimar(false);
    }
  };

  window.addEventListener("scroll", animationSection);

  return (
    <>
      <Banner {...BannerEmpresa} />
      <LineSeparation />

      <InfoSection {...homeObjOne} />

      <LineSeparation />

      {animar ? (
        <FadeInLeft duration="1s" delay="0.5s">
          <Pricing {...PricingObjOne} />
        </FadeInLeft>
      ) : (
        <Pricing {...PricingObjOne} />
      )}
      <LineSeparation />

      <ProductInfoSlide {...ProductSection} ProductListData={ProductListData} />
      <LineSeparation />
    </>
  );
};

export default Home;
